import { render, staticRenderFns } from "./SetupPanel.vue?vue&type=template&id=5926a917&scoped=true"
import script from "./SetupPanel.vue?vue&type=script&lang=js"
export * from "./SetupPanel.vue?vue&type=script&lang=js"
import style0 from "./SetupPanel.vue?vue&type=style&index=0&id=5926a917&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5926a917",
  null
  
)

export default component.exports