<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <SideBarFilter
        ref="filter"
        :filter="filter"
        :placeholder="'Search Name'"
        @clearFilter="clearFilter"
        @searchAll="filterActions"
        :hideStatusFilter="false"
        :hideSearchBar="true"
      >
        <template v-slot:filter-option>
          <div class="mb-3 input-select">
            <InputSelect
              title="Transaction Date"
              name="transaction-date"
              class="mt-2"
              v-model="filter.LastType"
              :options="options.dateType"
              valueField="value"
              textField="text"
            >
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >-- Select Transaction Date --</b-form-select-option
                >
              </template>
            </InputSelect>
          </div>
          <div v-if="filter.LastType == 0" class="mb-3">
            <InputDatePickerFilter
              textFloat="Transaciton Date From"
              name="transaction-date-from"
              ref="transaction-date-from"
              :max-datetime="filter.DateTo || initFilterDate.MaxDate"
              :value="filter.DateFrom"
              @input="
                (val) => {
                  filter.DateFrom = val;
                }
              "
            />
          </div>
          <div v-if="filter.LastType == 0" class="mb-3">
            <InputDatePickerFilter
              textFloat="Transaciton Date To"
              name="transaction-date-to"
              ref="transaction-date-to"
              :min-datetime="filter.DateFrom"
              :max-datetime="initFilterDate.MaxDate"
              :value="filter.DateTo"
              @input="
                (val) => {
                  filter.DateTo = val;
                }
              "
            />
          </div>
        </template>
      </SideBarFilter>
      <b-form>
        <b-row>
          <b-col> <h1 class="mr-sm-4 header-tablepage">SEGMENT</h1></b-col>
          <b-col class="text-right">
            <b-button @click.prevent="sidebarFilter" class="btn-filter">
              <font-awesome-icon icon="filter" class="d-md-none" />
              <span class="d-none d-md-block">
                <font-awesome-icon icon="filter" class="pointer" />
                <span class="btn-text"> Filter </span>
              </span>
            </b-button>
          </b-col>
        </b-row>
        <div>
          <div class="my-2 f-16">
            <div>Total Customer : {{ rowsCustomerList | numeral("0,0") }}</div>
          </div>
          <p class="my-2 f-16">
            {{ date }}
          </p>
        </div>
        <b-tabs class="mt-3" v-model="tabIndex">
          <b-tab title="Setup">
            <div class="bg-white black-color">
              <SetupPanel
                ref="setupPanel"
                :form="form"
                :v="$v"
                :id="id"
                :isSelectedCondition="conditionSettingValue"
                @settingCondition="settingCondition"
              />
            </div>
            <FooterAction @submit="checkForm()" routePath="/segment" />
          </b-tab>
          <b-tab title="Summary" v-if="parseInt(id) > 0">
            <div class="bg-white" v-if="tabIndex == 1">
              <Summary
                ref="summary"
                :filter="filter"
                :filterFreeze="filterFreeze"
                @setStat="setStat"
                :id="parseInt(id)"
              />
            </div>
          </b-tab>
          <b-tab title="Customer List" v-if="parseInt(id) > 0">
            <TabCustomerList
              :form="form"
              placeholder="Search Name"
              :filter="filterCustomer"
              :fields="fieldsCustomer"
              :items="itemsCustomer"
              :isBusy="isBusyCustomer"
              :rows="rowsCustomerList"
              :pageOptions="pageOptions"
              :showing="showing"
              :showingTo="showingTo"
              @getPage="getPage"
              @getPageOption="getPageOption"
            />
          </b-tab>
        </b-tabs>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading";
import SetupPanel from "@/components/segment/detail/SetupPanel";
import TabCustomerList from "@/components/segment/detail/customer/TabCustomerList";
import Summary from "@/components/segment/summary";
import moment from "moment";

export default {
  name: "segmentDetails",
  components: {
    OtherLoading,
    SetupPanel,
    TabCustomerList,
    Summary,
  },
  data() {
    return {
      date: "",
      id: this.$route.params.id,
      isLoading: true,
      isBusy: true,
      filter: {
        segment_id: this.$route.params.id,
        DateFrom: "",
        DateTo: "",
        TypeDateFilter: 2,
        LastType: 2,
      },
      filterFreeze: {
        segment_id: this.$route.params.id,
        DateFrom: "",
        DateTo: "",
        TypeDateFilter: 2,
        LastType: 2,
      },
      options: {
        dateType: [
          { text: "This Week", value: 1 },
          { text: "This Month", value: 2 },
          { text: "This Year", value: 3 },
          { text: "Custom", value: 0 },
        ],
      },
      initFilterDate: {
        MinDate: "",
        MaxDate: "",
      },
      form: {
        name: "",
        is_import: 0,
        is_auto_update: 0,
        is_process_done: 0,
        condition: [],
        condition_group: [
          {
            group: 1,
            import_condition: "",
            operation_type: "",
            group_operation_type: "and",
            condition: [],
          },
        ],
      },
      list: {
        privilegeCode: [{ id: 0, name: "Please Select Privilege Code" }],
        condition: [{ id: 0, name: "Please Select Condition" }],
      },
      filterCustomer: {
        id: 0,
        take: 10,
        page: 1,
      },
      fieldsCustomer: [
        {
          label: "Member ID",
          key: "member_id",
        },
        {
          label: "First Name",
          key: "firstNameTh",
        },
        {
          label: "Last Name",
          key: "lastNameTh",
        },
        {
          label: "Telephone",
          key: "telephone",
        },
        {
          label: "Eamil",
          key: "email",
        },
      ],
      itemsCustomer: [],
      isBusyCustomer: true,
      rowsCustomerList: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      showing: 1,
      showingTo: 10,
      result: 0,
      conditionSettingValue: 2,
      freezeCondition: [],
      tabIndex: 0,
    };
  },
  validations: {
    form: {
      name: { required },
      condition_group: {
        $each: {
          condition: {
            $each: {
              condition_type_id: {
                required: requiredIf(function (item) {
                  return !item.condition_type_id;
                }),
              },
              operation_type_id: {
                required: requiredIf(function (item) {
                  return !item.operation_type_id;
                }),
              },
              condition_value: {
                required: requiredIf(function (item) {
                  return item.date_type == 1 || item.date_type == 2
                    ? false
                    : !item.condition_value;
                }),
              },
            },
          },
        },
      },
    },
  },
  watch: {
    // tabIndex(val) {
    //   console.log(val);
    // },
  },
  created: async function () {
    // console.log(object);
    if (this.id !== "0") {
      await this.getData();
      await this.getCustomerList();
    }

    this.isLoading = false;
    this.$v.$reset();
  },

  methods: {
    getData: async function () {
      this.isLoading = true;
      this.date =
        this.filter.LastType == 0
          ? `${this.$moment(this.filter.DateFrom).format(
              "DD MMM YYYY"
            )} - ${this.$moment(this.filter.DateTo).format("DD MMM YYYY")}`
          : this.$displayFilterDate(this.filter.LastType);

      await this.$store.dispatch("getSegmentDetail", this.id);
      var data = await this.$store.state.segment.stateSegmentDetail;

      var tempObj = [];
      this.initDateFilter();
      this.freezeCondition = [...data.detail.condition_group];
      if (data.result == 1) {
        let index = 1;
        for (const group of data.detail.condition_group) {
          var tempObj = [];
          group.group = index;
          group.group_operation_type =
            group.group_operation_type || group.condition.length > 0
              ? group.condition[0].group_operation_type || "and"
              : "and";
          for (const list of group.condition) {
            if (
              !tempObj.find(
                (el) => el.condition_type_id == list.condition_type_id
              )
            ) {
              let value = group.condition
                .filter((el) => el.condition_type_id == list.condition_type_id)
                .map((el) => el.condition_value);
              tempObj.push({
                ...list,
                condition_value: value.length == 1 ? value[0] : value,
              });
            }
          }
          group.condition = tempObj;
          index++;
        }

        this.form = data.detail;
        if (this.form.condition_group.length) this.conditionSettingValue = 1;
        else this.conditionSettingValue = 2;
      }
      this.isLoading = false;
    },
    settingCondition(val, form) {
      if (val == 1) {
        // this.conditionSelect = true;
        if (form) {
          this.form.condition_group = form;
        }
        this.conditionSettingValue = val;
      } else {
        this.conditionSettingValue = val;
        this.form.condition_group = [];
      }
    },
    async getCustomerList() {
      this.isBusyCustomer = true;
      this.filterCustomer.id = parseInt(this.$route.params.id);
      await this.$store.dispatch("getCustomerSegmentList", this.filterCustomer);
      const data = this.$store.state.segment.customerList;
      this.rowsCustomerList = data.detail.count;
      if (this.rowsCustomerList > 0) {
        this.itemsCustomer = data.detail.result;
      } else {
        this.itemsCustomer = [];
      }
      this.isBusyCustomer = false;
    },
    async checkForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$bus.$emit("showLoading");

      await this.submit();

      this.$bus.$emit("hideLoading");
    },
    async submit() {
      let payload = {
        ...JSON.parse(JSON.stringify(this.form)),
        import_segment: this.$refs.setupPanel.importExcelForm,
      };

      let data = null;

      var tempObj = [];

      for (const group of payload.condition_group) {
        var tempObj = [];

        for (const list of group.condition) {
          delete list.id;

          list.operation_value = list.operation_value
            ? list.operation_value
            : 0;
          list.date_type = list.date_type ? list.date_type : 0;
          if (typeof list.condition_value == "object") {
            for (const value of list.condition_value) {
              tempObj.push({ ...list, condition_value: value });
            }
          } else {
            tempObj.push(list);
          }
        }

        group.condition = tempObj;
      }

      // payload.condition = tempObj;

      if (this.id == 0) {
        await this.$store.dispatch("createSegmentList", payload);
        data = this.$store.state.segment.stateCreateSegment;
      } else {
        await this.$store.dispatch("updateSegmentList", payload);
        data = this.$store.state.segment.stateUpdateSegment;
      }

      if (data.result == 1) {
        this.successAlert().then(() => this.$router.push("/segment"));
      } else {
        this.errorAlert(data.message);
      }
    },
    getPage(value) {
      this.filterCustomer = value;
      this.getCustomerList();
    },
    getPageOption(value) {
      this.filterCustomer = value;
      this.getCustomerList();
    },
    searchFilter(value) {
      this.filterCustomer = value;
      this.getCustomerList();
    },
    sidebarFilter() {
      this.$refs.filter.show();
    },
    setStat(val, date) {
      this.date = date;
      // if (val) {
      //   this.stat.totalCustomer = val.totalCustomer;
      //   this.stat.percentCustomer = val.percentCustomer;
      // }
    },
    clearFilter() {
      this.filter = {
        segment_id: this.id,
        DateFrom: "",
        DateTo: "",
        TypeDateFilter: 2,
        LastType: 2,
      };
      this.filterFreeze = {
        segment_id: this.id,
        DateFrom: "",
        DateTo: "",
        TypeDateFilter: 2,
        LastType: 2,
      };
      this.$refs.filter.hide();
      this.$store.dispatch("clearFilter");
      this.filterActions();
    },
    filterActions() {
      if (this.filter.DateFrom)
        this.filter.DateFrom = this.$moment(this.filter.DateFrom).format(
          "YYYY-MM-DD"
        );
      if (this.filter.DateTo)
        this.filter.DateTo = this.$moment(this.filter.DateTo).format(
          "YYYY-MM-DD"
        );

      this.filterFreeze = { ...this.filter };

      for (const [keys, value] of Object.entries(this.filter)) {
        if (typeof value == "object") {
          this.filterFreeze[keys] = value.map((el) => el.id);
        }
      }

      this.date =
        this.filter.LastType == 0
          ? `${this.$moment(this.filter.DateFrom).format(
              "DD MMM YYYY"
            )} - ${this.$moment(this.filter.DateTo).format("DD MMM YYYY")}`
          : this.$displayFilterDate(this.filter.LastType);
    },
    initDateFilter() {
      let toDay = moment().format("YYYY-MM-DDT00:00:00");
      // this.initFilterDate.MinDate = moment(toDay).day(-365).format();
      this.initFilterDate.MaxDate = toDay;
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 350px) and (max-width: 991px) {
  .footer-action {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.footer-action {
  margin-left: var(--margin-spacing);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  display: flex;
  flex-direction: row;
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
  z-index: 600;
}
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
</style>
